export const primeiroNome = value => {
  if (!value) return ''
  const nomeArray = value.split(' ')
  const capitalizarNome = nomeArray[0].charAt(0).toUpperCase() + nomeArray[0].slice(1)
  return capitalizarNome
}

export const corrigirFuncaoUsuario = value => {
  if (!value) return ''
  const funcaoArray = value.split('_')
  return funcaoArray[1]
}

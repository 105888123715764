<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary">
          <img
            src="@/assets/images/logo/logo-mercadohit.svg"
            width="150"
          >
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Conecte os seus hits! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Faça login na sua conta e conecte-se com artistas
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="usuarioEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="email@exemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{name:'esqueci-senha'}">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="usuarioSenha"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || respostaApi"
              >
                Entrar
                <b-spinner
                  v-if="respostaApi"
                  small
                  label="Carregando"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Não tem conta? </span>
            <b-link :to="{name:'autenticacao-cadastro'}">
              <span>&nbsp;Cadastre-se</span>
            </b-link>
          </b-card-text>
          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
                href="https://www.facebook.com/mercadohit"
                target="_blank"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="instagram"
              href="https://www.instagram.com/mercadohit"
              target="_blank"
            >
              <feather-icon icon="InstagramIcon" />
            </b-button>
            <b-button
              variant="google"
              href="mailto:contato@mercadohit.com"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div>

          <b-card-text class="text-center mt-2">
            <span>Precisa de ajuda? </span>
            <b-link href="https://wa.me/558592401035/" target="_blank">
              <span>&nbsp;Fale com a gente!</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>

    <!-- basic modal -->
    <b-modal
      id="confirmacaoCarregando"
      ref="confirmacaoCarregando"
      centered
      title="Confirmação"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <b-spinner
          class="mt-2"
          variant="primary"
        /><br>
        <strong>Só um momento!</strong><br> Estamos verificando sua confirmação de cadastro. Apenas mais alguns segundos e tudo estará confirmado!
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import jwt_decode from 'jwt-decode'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

import { primeiroNome } from '@core/utils/funcoes'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      Email: {
        required: 'O email é obrigatório.',
        email: 'O email deve ser um email válido.',
      },
      Password: {
        required: 'A senha é obrigatória.',
      },
    },
  },
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      usuarioSenha: null,
      usuarioEmail: null,
      sideImg: require('@/assets/images/pages/login-v2.png'),

      // validation rules
      required,
      email,
      respostaApi: false,
      token: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    if (this.$route.name === 'confirmar-cadastro') {
      this.token = this.$route.params.token

      this.confirmarToken()

    }
  },
  methods: {

    primeiroNome,

    confirmarToken() {

      // MODAL - CONFIRMAÇÃO CARREGANDO - ABRIR
      this.$refs.confirmacaoCarregando.show()

      useJwt.usuarioConfirmarCadastro({
        token: this.token,
      })
      .then(response => {
        
        this.$swal({
          title: 'Cadastro concluído!',
          text: 'Parabéns! Seu cadastro foi confirmado com sucesso.',
          icon: 'success',
          confirmButtonText: 'Ir para o login',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {

            //this.$router.push({ name: 'autenticacao-login' })

          } else {

            //this.$router.push({ name: 'autenticacao-login' })

          }
        })
        // this.$router.push('/')
      })
      .catch(error => {
        this.$swal({
          title: 'Erro!',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

      })
      .finally(() => {

        // MODAL - CONFIRMAÇÃO CARREGANDO - FECHAR
        this.$refs.confirmacaoCarregando.hide()

      })
      
    },

    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.login({
            email: this.usuarioEmail,
            senha: this.usuarioSenha,
          })
            .then(response => {
              const tokenBearer = response.headers.authorization
              const tokenBearerRemover = tokenBearer.split('Bearer ')
              const token = tokenBearerRemover[1]

              const usuarioDataDecode = jwt_decode(token)

              const usuarioData = {
                sub: usuarioDataDecode.sub, 
                iat: usuarioDataDecode.iat, 
                exp: usuarioDataDecode.exp, 
                name: usuarioDataDecode.name, 
                iss: usuarioDataDecode.iss, 
                saldo: usuarioDataDecode.saldo, 
                authority: usuarioDataDecode.authority[0].tipoPerfil,
                email: usuarioDataDecode.email, 
                ability: usuarioDataDecode.authority[0].ability,
                completarCadastro: usuarioDataDecode.completarCadastro,
                assinaturaAtiva: usuarioDataDecode.assinaturaAtiva,
                formaPagamento: usuarioDataDecode.formaPagamento,
                primeiroAcesso: usuarioDataDecode.primeiroAcesso
              }

              useJwt.setToken(token)
              localStorage.setItem('usuarioData', JSON.stringify(usuarioData))
              this.$ability.update(usuarioData.ability)
              
              if (usuarioData.primeiroAcesso) {
                this.$router.push('/primeiro-acesso');
              } else {
                this.$router.replace(getHomeRouteForLoggedInUser(usuarioData.authority))
                  .then(() => {
                    if (localStorage.getItem('originalUrl') && localStorage.getItem('originalUrl') !== '/') {
                      const originalUrl = localStorage.getItem('originalUrl');
                      localStorage.removeItem('originalUrl');
                      this.$router.push(originalUrl);
                    }
                  })
                  .finally(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Olá, ${primeiroNome(usuarioData.name)}`,
                        icon: 'CoffeeIcon',
                        variant: 'primary',
                        text: 'Você fez o login com sucesso.',
                      },
                    });
                  });
              }
            })
            .catch(error => {
              if (error.response) {
                const erro = error.response.data.message;
                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: erro,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              }
            })
            .finally(() => {
              this.respostaApi = false;
            });
          }
        });
      },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-text img {
  vertical-align: baseline;
}

</style>
